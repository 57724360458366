// import bus from "../main";
// import store from "../store";
import axios from "axios";
import router from "../router";
import store from "../store";
import {TokenService} from "@/services/storage.service";
import LoginService from "@/services/login.service";

export function createQuery(data) {
    if (!data || typeof data != "object") return "";
    let query = "";
    for (const key in data) {
        let value = data[key] == null ? "" : data[key];
        query += `&${key}=${value}`;
    }
    if (query.startsWith("&")) query = query.substring(1);
    return query;
}

const ApiService = {
    // Stores the 401 interceptor position so that it can be later ejected when needed'
    _401interceptor: null,
    i: 0,

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },
    getFile(resource) {
        return axios.get(resource, {
            responseType: "blob",
        });
    },
    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${
            TokenService.getToken() || ""
        }`;
    },
    setLanguage() {
        axios.defaults.headers.common["Accept-Language"] =
            TokenService.getLanguage() || "lt";
    },
    formData(resource, data) {
        return axios.post(resource, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    removeHeader() {
        axios.defaults.headers.common = {};
    },
    async logOut() {
        try {
            await LoginService.logOut();
        } catch (error) {
            console.log(error);
        } finally {
            TokenService.removeFullName();
            TokenService.removeEmail();
            TokenService.removeToken();
            this.removeHeader();
            router.push({name: "Home"});
        }
    },
    get(resource, config = {}) {
        this.setLanguage();
        return axios.get(resource, config);
    },
    post(resource, data, config = {}) {
        this.setLanguage();
        return axios.post(resource, data, config);
    },
    customPost(resource, data) {
        return axios.post(resource, data, {
            headers: {"Content-Type": "application/xml"},
        });
    },
    put(resource, data) {
        return axios.put(resource, data);
    },
    delete(resource) {
        return axios.delete(resource);
    },

    // regirtration 403 un registration actions
    openOneId() {
        const path = process.env.VUE_APP_ROOT_URL;
        if (path.indexOf("e-advokat") > 0) {
            this.WinPrint = window.open(
                "https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=e-advokat&redirect_uri=http://e-advokat.uz/one-id&scope=e-advokat&state=eyJtZXRob2QiOiJJRFBXIn0=",
                "_self",
                "One Id",
                "left=0,top=0,width=1000,height=1000,toolbar=0,scrollbars=0,status=0"
            );
        } else {
            this.WinPrint = window.open(
                "https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=e-advokat&redirect_uri=http://localhost:1100/one-id&scope=e-advokat&state=eyJtZXRob2QiOiJJRFBXIn0=",
                "_self",
                "One Id",
                "left=0,top=0,width=1000,height=1000,toolbar=0,scrollbars=0,status=0"
            );
        }
        // this.WinPrint = window.open(
        //   "https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=e-advokat&redirect_uri=https://localhost:1000/oneId&scope=e-advokat&state=eyJtZXRob2QiOiJJRFBXIn0=",
        //   "_self",
        //   "One Id",
        //   "left=0,top=0,width=1000,height=1000,toolbar=0,scrollbars=0,status=0",
        // );
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data);
    },
    // transformRequest: [(data, headers) => {
    //     delete headers.common.Authorization
    //     return data
    // }],
    mount401Interceptor() {
        this._401interceptor = axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {

                this.i++;
                if (this.i == 1) {
                    if (
                        error.request.status === 403 ||
                        error.request.status === 401
                    ) {
                        // await store.dispatch("auth/logout");
                        // TokenService.removeToken();
                        ApiService.removeHeader();
                        // TokenService.removeEmail();
                        // TokenService.removeFullName();
                        router.push("/");
                        let email = "";
                        let fullName = "";
                        store.commit("lawyer/USER", {email, fullName});
                        // this.openOneId();
                        throw error;
                    } else {
                        if (
                            error.request.status != 200 &&
                            error.request.status != 417
                        ) {
                            if (!error.config.dontShowError) {
                                const message =
                                    error.response?.data?.message?.slice(0, 50) ??
                                    error.response?.data?.details ??
                                    error.response?.data?.error ??
                                    error.message;
                                // $toast.error(message);
                                // bus.$showError(message);
                                console.log("ERROR SERVICE", message);
                                throw error;
                            }
                        }
                    }
                }
                this.i = 0;
                // If error was not 401 just reject as is
                throw error;
            }
        );
    },

    unmount401Interceptor() {
        // Eject the interceptor
        axios.interceptors.response.eject(this._401interceptor);
    },
};

export default ApiService;
