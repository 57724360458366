export const locale = {
    actions: "Амаллар",
    name: {
        lt: "Nomi (lotin)",
        ru: "Название (русский)",
        uz: "Номи (кирилл)",
        en: "Name (english)",
    },
    lang: {
        russian: "Русский",
        uzbekLatin: "O‘zbek (Lotin)",
        uzbekKrill: "Ўзбек (кирилл)",
        english: "English",
    },
    regions: "Вилоят",
    corruption: "Коррупция",
    lawyerH: "Aдвокатлар ҳайъати",
    oneIIdAuth: "OneID autentifikatsiyasi",
    dutyScheduleHeader: "{region}да {year} йил {month} ойида жиноят ишлари бўйича давлат ҳисобидан юридик ёрдам кўрсатувчи адвокатларнинг НАВБАТЧИЛИК ЖАДВАЛИ",
    oneIIdAuthDesc:
        "OneID autentifikatsiyasi orqali tizimga kirish jarayoni boshlandi. Iltimos, Sahifa yuklanishi tugallanishini kuting...",
    bankName: "Банк номи",
    diplom: {
        title: "Диплом маълумотлари"
    },
    forPersonsWithDisabilities: "Ногиронлиги бўлган шахслар учун",

    work: {
        title: "Мехнат фаолияти",
        start_date: "Иш бошланган санаси",
        end_date: "Иш тугатган санаси",
        company_name: "Ташкилот",
        company_inn: "СТИР",
        position_name: "Лавозим",
        structure_name: "Бўлим",

    },
    lexUz: "Ўзбекистон Қонун ҳужжатлари маълумотлари миллий базаси",
    adliya: "Ўзбекистон Республикаси Адлия вазирлиги расмий сайти",
    yurxizmat:"Ўз-ўзига ҳуқуқий хизмат кўрсатиш “Legal Tech”",
    conviction: {
        title: "Судланганлик ёки судланмаганлик ҳолати"
    },
    new: "Янги",
    orderNumber: "Буйруқ раками",
    announcement: {
        menu: "Вакансия эълон қилиш",
        title: "Вакантлар",
        description: "Стажёр ва адвокат ёрдамчиси учун",
        address: "Манзили",
        phone: "Телефон рақами",
        contragent: "Ташкилот номи",
        sendDate: "Ҳужжат юборилган вақти",
        createDate: "Яратилган сана",
        endDate: "Вакант муддати",
        status: "Ҳолати",
        classificatory: "Классификатор",
        count: "Вакант сони",
        longitude: "Жойлашган жойи (Узунлик)",
        latitude: "Жойлашган жойи (Кенглик)",
        vacancy: "Вакансия",
        vacancyType: "Вакансия тури",
        isPayedTrue: "Тўланадиган",
        isPayedFalse: "Тўланмайдиган",
        amount: "Сумма",
        payTitle: "Ищ хақи"

    },
    sidebar: {
        calendar: "Календарь",
        myWorks: "Менинг ишларим",
        requests: "Аризалар",
        contracts: "Шартномалар",
        incoming: "Кирувчи",
        outgoing: "Чиқувчи",
        lawyerApplication: "Адвокатга ариза юбориш",
        lawyerApplicationForm: "Ариза  юбориш",
        lawyerApplicationIntern: "Стажёр ва адвокат ёрдамчилари аризалари",
        lawyerApplicationInternRestr: "Стажёр реестирига қўшиш",
        lawyerApplicationList: "Менинг аризаларим",
        draft: "Қоралама",
        docTemplate: "Шаблонлар",
        orders: "Ордерлар",
        statisticInfo: "Статистика",
        profile: "Профил",
        letterDocuments: "Хатлар ва ҳужжатлар",
        control: "Бошқарув",
        queryLog: "Сўровлар журнали",
        userGuide:"Foydalanuvchi qo'llanmalari",
        documentsChamberOfAdvocates:"Advokatlar palatasi hujjatlari",
        legalServiceInfo:"Advokatlik xizmatidan foydalanish"


    },
    mentor: "Ментор",
    expertise: {
        paymentStatus: "Тўлов ҳолати",
        actionType: "Ҳаракат тури",
        type: "Тип экспертизы",
        organization: "Организация",
        researchType: "Тип исследования",
        comment: "Комментарий",
        paymentDetail: "Тўлов тафсилотлари",
        response: "Тайинланган експертизалар сони",
        conclusionNumber: "Хулоса рақами",
        deadline: "Крайний срок",
        paymentAt: "Тўланган сана",
        docType:"Тури",
        status: {
            DIRECTED: "Хужжат кўриб чиқиш учун юборилди"
        }

    }
};
