/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/core/i18n/i18n.js";
import vSelect from "vue-select";
import Toast from "vue-toastification";
import AOS from "aos";
import Maska from "maska";
import naive from "naive-ui";
import VueSocialSharing from "vue-social-sharing";
import CContent from "@/components/CContent.vue";
import COverlay from "@/components/COverlay.vue";
import vClickOutside from "click-outside-vue3";
import Vue3Toasity from "vue3-toastify";

import InlineSvg from "vue-inline-svg";

import "aos/dist/aos.css";
import "vue-select/dist/vue-select.css";
import "vue3-toastify/dist/index.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import "vue-toastification/dist/index.css";
import "@/assets/scss/main.scss";
import "./assets/tailwind.css";

import "vue-toast-notification/dist/theme-sugar.css";
import ToastPlugin from "vue-toast-notification";

import ApiService from "./services/api.service";
import { TokenService } from "./services/storage.service";
// SET THE BASE_URL OF THE API
ApiService.init(process.env.VUE_APP_ROOT_API);
ApiService.mount401Interceptor();
ApiService.setLanguage();

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mount401Interceptor();
}

const app = createApp(App);

// global components
app.component("CContent", CContent);
app.component("COverlay", COverlay);

app.use(VueSocialSharing);

app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

app.use(
  AOS.init({
    once: true,
  })
);
const baseUrl = process.env.VUE_APP_ROOT_URL;
const baseUrl2 = process.env.VUE_APP_ROOT_URL2;
const baseDocUrl = process.env.VUE_APP_DOC_SERVER_URL;

import Util from "@/core/utils/globalMethods";
// timetype
app.config.globalProperties.$getName = Util.getName;
app.config.globalProperties.$localeKey = Util.localeKey;
app.config.globalProperties.$timetype = Util.timetype;
app.config.globalProperties.$showError = Util.showError;
app.config.globalProperties.$errorMsg = Util.errorMsg;
app.config.globalProperties.$showSuccess = Util.showSuccess;
app.config.globalProperties.$showInfo = Util.showInfo;
app.config.globalProperties.$successMsg = Util.successMsg;
app.config.globalProperties.$showWarning = Util.showWarning;
app.config.globalProperties.$getImgPath = Util.getImgPath;
app.config.globalProperties.$baseUrl = baseUrl;
app.config.globalProperties.$baseUrl2 = baseUrl2;
app.config.globalProperties.$baseDocUrl = baseDocUrl;
import "./dateProtypes";

app.component("v-select", vSelect);
app.use(Maska);
app.use(ToastPlugin);
app.use(Vue3Toasity);
app.use(vClickOutside);
app.use(naive);
app.component("inline-svg", InlineSvg);
app.use(VueSocialSharing);
app.use(i18n);
app.use(store);
app.use(router);
app.mount("#app");
